export const combineCartItems = (currentItems = [], newItem = {}) => {
  const itemFound = currentItems.find(
    item =>
      item.checkoutData.variant_id === newItem.checkoutData.variant_id &&
      item.checkoutData.quantity === newItem.checkoutData.quantity
  );
  if (itemFound) {
    return currentItems;
  }
  return [...currentItems, newItem];
};

export const excludeCurrentOrder = (currentItems = [], currentOrder = {}) => {
  const newItems = currentItems.filter(item => {
    return (
      `${item.checkoutData.product_id}_${item.checkoutData.quantity}` !==
      `${currentOrder.checkoutData.product_id}_${currentOrder.checkoutData.quantity}`
    );
  });
  return newItems;
};
