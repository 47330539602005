import React from "react";
import DeclineButtonV0 from "./DeclineButtonV0";
import DeclineButtonV1 from "./DeclineButtonV1";
import useTriggerOnTimeout from "../../hooks/useTriggerOnTimeout";

const DeclineButtonComponent = props => {
  const { backgroundDesign = "design1", triggerOnTimeout = true } = props;

  useTriggerOnTimeout(triggerOnTimeout);

  switch (backgroundDesign) {
    case "no-design":
      return <DeclineButtonV0 {...props} />;
    case "design1":
    default:
      return <DeclineButtonV1 {...props} />;
  }
};

export default DeclineButtonComponent;
