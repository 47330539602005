import React, { useContext } from "react";
import Context from "../../context/Context";
import submitOrders from "../../functions/submitOrders";
import { Builder } from "@builder.io/react";
import * as S from "./DeclineButtonV1.styles";
import { excludeCurrentOrder } from "../../utils/upsellCart";
import { useCurrentOffer } from "../../hooks/useCurrentOffer";

const DeclineButtonComponent = ({ backgroundDesign, children }) => {
  const bgDesign = backgroundDesign ?? "design1";

  const {
    setCurrentIndex,
    buttonClicked,
    setButtonClicked,
    upsellCart,
    userToken,
    isCheckoutApiVersion2
  } = useContext(Context);

  const currentOffer = useCurrentOffer();
  const { nextIndexNo, hasNextOffer } = currentOffer;

  const triggerButton = () => {
    if (!buttonClicked && !Builder.isEditing) {
      setButtonClicked(true);

      const moveNext = () => {
        // disable loading
        setButtonClicked(false);

        // scroll to top
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

      if (!hasNextOffer) {
        const newCartItems = excludeCurrentOrder(upsellCart, currentOffer);

        // submit order
        submitOrders(userToken, newCartItems, {}, isCheckoutApiVersion2).then(
          () => {
            setTimeout(() => {
              setButtonClicked(false);
            }, 3000);
          }
        );
      } else {
        setCurrentIndex(nextIndexNo);
        moveNext();
      }
    }
  };

  const ButtonContent = ({ children }) => {
    if (buttonClicked) {
      return (
        <img
          src="/images/three-dots.svg"
          width={120}
          height={30}
          alt="Loading..."
          className="button-loading"
        />
      );
    } else {
      return children;
    }
  };

  return (
    <S.Button
      design={bgDesign}
      className={`${buttonClicked ? "clicked" : ""}`}
      onClick={triggerButton}
    >
      <ButtonContent>
        <div>{children || `{add text component}`}</div>
      </ButtonContent>
    </S.Button>
  );
};

export default DeclineButtonComponent;
