import { useContext, useEffect, useState } from "react";
import Context from "../context/Context";
import { Builder } from "@builder.io/react";
import { excludeCurrentOrder } from "../utils/upsellCart";
import submitOrders from "../functions/submitOrders";

const useTriggerOnTimeout = (triggerOnTimeout = false) => {
  const {
    countDownEnd,
    currentIndex,
    buttonClicked,
    setButtonClicked,
    selectorData,
    upsellCart,
    userToken,
    isCheckoutApiVersion2
  } = useContext(Context);

  const [remainingTime, setRemainingTime] = useState(-1);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!triggerOnTimeout || !userToken) return;

      const countDownEndTime = new Date(countDownEnd).getTime();
      const currentTime = new Date().getTime();
      const intervalTime =
        countDownEndTime - currentTime > 0 ? countDownEndTime - currentTime : 0;

      if (intervalTime === 0) {
        if (!buttonClicked && !Builder.isEditing) {
          setButtonClicked(true);

          const { offers } = selectorData;
          const currentOrder = offers.at(currentIndex) || {};
          Object.assign(currentOrder, { offerIndex: currentIndex });

          const newCartItems = excludeCurrentOrder(upsellCart, currentOrder);
          submitOrders(userToken, newCartItems, {}, isCheckoutApiVersion2).then(
            () => {
              setTimeout(() => {
                setButtonClicked(false);
              }, 3000);
            }
          );
        }
        return;
      }
      setRemainingTime(intervalTime);
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    triggerOnTimeout,
    remainingTime,
    countDownEnd,
    buttonClicked,
    selectorData,
    currentIndex,
    setButtonClicked,
    upsellCart,
    userToken
  ]);
};

export default useTriggerOnTimeout;
