import styled from "styled-components";

export const Button = styled.button`
  &,
  &:focus {
    background-color: #c95f3d !important;
    background: #c95f3d !important;
    padding: 15px;
    display: block;
    border-radius: 10px;
    margin: auto;
    cursor: pointer;
    min-height: 30px;
    width: 100%;
    border: none;
  }

  &.design1:hover .builder-text {
    color: #000 !important;
  }

  & .button-loading {
    display: block;
    margin: 0 auto;
  }

  &.clicked {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
