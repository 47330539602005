import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
`;

export const LoadingButton = styled.div`
  ${({ loadingBgColor }) => `
  background: ${loadingBgColor ?? `#BC2E3E`};
`}
  ${({ borderRadius = 4 }) => `border-radius: ${borderRadius}px;`}
  ${({ minHeight = 0 }) => `min-height: ${minHeight}px;`}
  padding: 10px;
  display: flex;
  align-items: center;
  &>img {
    margin: auto;
  }
`;
